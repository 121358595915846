import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { useHistory, useRouteMatch } from "react-router";
import { Card, Input, Row, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { TableProps } from "antd/es/table";
import omit from "lodash/omit";
import AddButton from "../../../../components/AddButton";
import useAppAuth0 from "../../../../hooks/useAppAuth0";
import styles from "./index.module.scss";
import { PromotionDiscountType, PromotionListModel, PromotionModel } from "../../../../types/model/promotionV2";
import ResizableTitle from "../../../../components/ResizableTitle";
import { getTotalDisplay } from "../../../../utils/getTotalDisplay";
import * as eComPromotionAPI from "../../../../services/promotionV2";
import { getOffset } from "../../../../utils/getPagination";
import useResizableTableHeader from "../../../../hooks/useResizableTableHeader";
import getPromotionTableColumns from "./getPromotionTableColumns";
import { PromotionSource } from "../components/PromotionForm";

function PromotionList() {
  const { user } = useAppAuth0();
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    searchText: withDefault(StringParam, undefined),
  });
  const history = useHistory();
  const { path } = useRouteMatch();
  const columnsToDisplay = useResizableTableHeader<PromotionModel>(getPromotionTableColumns());
  const [searchText, setSearchText] = useState(query.searchText);
  const [isPromotionListPending, setIsPromotionListPending] = useState(false);
  const [promotionList, setPromotionList] = useState<PromotionListModel>({} as PromotionListModel);

  const refreshPromotionList = useCallback(() => {
    setIsPromotionListPending(true);
    eComPromotionAPI
      .fetchPromotionList({
        ...omit(query, ["page"]),
        offset: getOffset(query.page, query.limit),
      })
      .then((res) => {
        setPromotionList(res);
      })
      .catch((err) => {})
      .finally(() => {
        setIsPromotionListPending(false);
      });
  }, [query]);

  useEffect(() => {
    refreshPromotionList();
    setSearchText(query.searchText);
  }, [query, refreshPromotionList]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleInputPressEnter = () => {
    setQuery({
      ...query,
      searchText: searchText?.trim(),
      page: searchText?.trim() ? 1 : query.page,
    });
  };

  const handleOnRowClick: TableProps<PromotionModel>["onRow"] = (record) => ({
    onClick: () =>
      history.push({
        pathname: `${path}/${record.id}`,
        search: `source=${
          record.discountType === PromotionDiscountType.BUY_ONE_GIFT_ONE
            ? PromotionSource.ECOM
            : PromotionSource.RECURLY
        }`,
      }),
  });

  return (
    <Card
      title="Promotion list"
      extra={user.canCreatePromotion && <AddButton text={"+ Add new Promotion"} />}
      className="page-container"
    >
      <Row className={styles.filter}>
        <Input
          allowClear
          value={searchText}
          onChange={handleInputChange}
          onBlur={() => setSearchText((prevState) => prevState?.trim())}
          onPressEnter={handleInputPressEnter}
          className={styles.promotionNameInput}
          prefix={<SearchOutlined />}
          placeholder="Search promotion code or applied base price"
        />
      </Row>

      <Table<PromotionModel>
        loading={{
          spinning: isPromotionListPending,
        }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        scroll={{ scrollToFirstRowOnChange: true, x: "100%", y: "max-content" }}
        rowKey={(record) => record.id}
        columns={columnsToDisplay}
        dataSource={promotionList.list}
        pagination={{
          showTotal: getTotalDisplay,
          current: query.page,
          pageSize: query.limit,
          total: promotionList.total,
          defaultCurrent: 1,
          onChange: (page, pageSize) => {
            setQuery({
              ...query,
              page,
              limit: pageSize,
            });
          },
        }}
        onRow={handleOnRowClick}
      />
    </Card>
  );
}

export default PromotionList;
